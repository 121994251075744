import React, {useState, Fragment} from "react";
import Moment from 'moment'
import { Transition, Dialog, RadioGroup } from "@headlessui/react";
import 'moment/locale/et';
import {Calendar, momentLocalizer} from "react-big-calendar";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import {extendMoment} from "moment-range";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const moment = extendMoment(Moment);

const shifts = [
  { name: 'Esimene vahetus', time: <>
      <div>08:00-12:00</div>
      <div>13:00-16:00</div>
    </>, initials: '1. ' },
  { name: 'Teine vahetus', time: <>
      <div>11:00-14:00</div>
      <div>15:00-19:00</div>
    </>, initials: '2. ' },
  { name: 'Määra käsitsi', time: 'Vali käsitsi, mis ajale saab planeerida vastuvotte', initials: '? ' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Person(props) {
  const [selected, setSelected] = useState(shifts[0])

  const { t } = useTranslation();
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  let [isOpen, setIsOpen] = useState(true)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const handleSelect = (e) => {
    console.log('click', e);
    openModal();
  }
  return (
    <div className="mt-5">
      <>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-30 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
              &#8203;
            </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    { t('Set working time') }
                  </Dialog.Title>
                  <div className="mt-2">
                    <RadioGroup value={selected} onChange={setSelected}>
                      <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                      <div className="space-y-4">
                        {shifts.map((shift) => (
                          <RadioGroup.Option
                            key={shift.name}
                            value={shift}
                            className={({ checked, active }) =>
                              classNames(
                                checked ? 'border-transparent' : 'border-gray-300',
                                active ? 'ring-2 ring-indigo-500' : '',
                                'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <div className="flex items-center">
                                  <div className="text-sm">
                                    <RadioGroup.Label as="p" className="font-medium text-gray-900">
                                      {shift.name}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description as="div" className="text-gray-500">
                                      {shift.time}
                                    </RadioGroup.Description>
                                  </div>
                                </div>
                                <RadioGroup.Description as="div" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                  <div className="font-medium text-gray-900">{shift.initials}</div>
                                </RadioGroup.Description>
                                <div
                                  className={classNames(
                                    active ? 'border' : 'border-2',
                                    checked ? 'border-indigo-500' : 'border-transparent',
                                    'absolute -inset-px rounded-lg pointer-events-none'
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="mt-4 align-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={closeModal}
                    >
                      { t('Save') }
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
          <Link to={'/settings/schedule'}><ArrowLeftIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" /></Link>
          <span>
            Meiko Udras
          </span>
        </h3>
      </div>
      <div className="bg-white p-5 rounded-lg overflow-auto" style={{height: '726px'}}>
        <Calendar
          events={[]}
          selectable
          localizer={localizer}
          culture={'et'}
          showMultiDayTimes
          onSelectSlot={handleSelect}
        />
      </div>
    </div>
  )
}
