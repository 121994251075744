import React, {useState} from 'react'
import {DateTime} from "luxon";
import {findIndex} from "lodash";
import {Link} from "react-router-dom";
import {BanIcon, CheckIcon, InboxInIcon} from "@heroicons/react/outline";
import {PermissionDialog} from "../dialogs";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {REQUEST_VISIT_ACCESS} from "../../queries/visits";


export default function VisitHistoryItem(props) {
  const { t } = useTranslation();
  const { isLast=false, visit, index, activeAccount, profile, visitAccessRequests } = props;
  const [permissionDialogVisible, showPermissionDialog] = useState(false);
  const [requestedPermissionState, setRequestPermission] = useState(false);

  const formattedDate = DateTime.fromISO(visit.visit_time).setLocale('et').toLocaleString(DateTime.DATE_MED);
  const hasAccess = visit.Account.id === activeAccount.id || findIndex(visit.VisitAccesses, {account_id: activeAccount.id}) > -1

  const [requestVisitAccess, { loading, error }] = useMutation(REQUEST_VISIT_ACCESS, {
    onCompleted(data) {
      // console.log('data', data);
    },
  });

  let requestedPermission = requestedPermissionState;

  (visitAccessRequests || []).forEach(accessRequest => {
    if((accessRequest?.metadata?.visits || []).indexOf(visit.id) > -1) {
      requestedPermission = true;
    }
  })

  // const requestedPermission = false;
  const requestPermission = async () => {
    await requestVisitAccess({variables: {
      object: {
        animal_profile_id: profile.id,
        to_account_id: visit.Account.id,
        metadata: {
          visits: [visit.id]
        }
      }
    }})
    setRequestPermission(true);
  };

  let icon;

  if(hasAccess) {
    icon = (
      <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
        <CheckIcon className="h-5 w-5 text-white"/>
      </span>
    );
  } else {
    icon = (
      <span className="h-8 w-8 rounded-full bg-yellow-500 flex items-center justify-center ring-8 ring-white">
        <BanIcon className="h-5 w-5 text-white"/>
      </span>
    )

    if(requestedPermission) {
      icon = (
        <span title={t('Permission request sent')}
              className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
          <InboxInIcon className="h-5 w-5 text-white"/>
        </span>
      )
    }
  }

  return (
    <>
      <li key={visit.id}>
        <div className="relative pb-8">
          {!isLast ? <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          /> : null}
          <Link to={`/clients/${profile.id}/visits/${visit.id}`} onClick={e => {
            if(!hasAccess) {
              e.preventDefault();
              showPermissionDialog(true);
            }
          }}  className="relative flex space-x-3 hover:underline">
            <div>
              { icon }
            </div>
            <div className="min-w-0 flex-1 flex justify-between space-x-4">
              <div>
                <p className="text-sm text-gray-500">
                  { t('Clinic') }{" "}
                  <span className="font-medium text-gray-900">
                            { t('visit') } #{visit.visit_id}
                          </span>
                </p>
                <p className="text-sm text-gray-500 font-bold">
                  { visit.Account.name }
                </p>
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                <time dateTime={ visit.visit_time }>{ formattedDate }</time>
              </div>
            </div>
          </Link>
        </div>
      </li>
      <PermissionDialog
        open={permissionDialogVisible}
        setOpen={showPermissionDialog}
        requestPermission={requestPermission}
        requestedPermission={requestedPermission}
      />
    </>
  )
}
