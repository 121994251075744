import React, {useEffect, useRef} from 'react'
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {useMutation, useQuery} from "@apollo/client";
import {pick} from 'lodash'
import {DateTime} from "luxon";
import moment from 'moment'
import 'moment/locale/et';

import {GET_ANIMAL_PROFILE, UPDATE_ANIMAL_PROFILE} from "../../queries/animalProfiles";
import {
  BirthdateInput,
  GenderSelect,
  NeuteringSelect,
  SearchableBreedSelect,
  SpeciesSelect
} from '../../components/form'
import {useToast} from "../../hooks/use-toast";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import TextInput from "../../components/form/text-input";
import {useAccount} from "../../hooks/use-account";
import {useBreadcrumbs} from "../../hooks/use-breadcrumbs";
import {editAnimalProfileUpdate} from "../../queries/updaters";

//TODO: validate more empty phone numbers
function EditAnimal(props) {
  let history = useHistory();
  let {path, url, params} = useRouteMatch();
  const {animalId} = params || {}
  const {data, loading: loadingProfile} = useQuery(GET_ANIMAL_PROFILE, {variables: {id: animalId}})
  const {eventData} = params || {}
  const initialEventData = eventData && JSON.parse(new Buffer(eventData, 'base64').toString('UTF-8'));
  const {AnimalProfile} = data || {};

  const {t} = useTranslation();
  console.log('default', AnimalProfile);
  const {register, handleSubmit, setError, formState, watch, setValue, control, reset} = useForm();
  const {breadcrumbs, setBreadcrumbs} = useBreadcrumbs();

  const {errors} = formState;
  const {message} = useToast();

  const {activeAccount, activeAccountId, activeRole} = useAccount();
  const email = useRef({});
  const phone = useRef({});
  email.current = watch("Owner.email", "");
  phone.current = watch("Owner.phone", "");

  useEffect(() => {
    setBreadcrumbs([
      {
        label: t('Clients'),
        to: '/clients'
      }
    ]);

    return () => {
      setBreadcrumbs([]);
    }
  }, [activeAccount]);

  useEffect(() => {
    if (AnimalProfile) {
      reset({
        ...AnimalProfile,
        date_of_birth: moment(AnimalProfile.date_of_birth).format('DD.MM.YYYY')
      });
    }
  }, [AnimalProfile])

  useEffect(() => {
    if (initialEventData) {
      if (initialEventData.animal_name) {
        setValue("name", initialEventData.animal_name);
      }
      if (initialEventData.email) {
        setValue("Owner.email", initialEventData.email);
      }
      if (initialEventData.phone) {
        setValue("Owner.phone", initialEventData.phone);
      }
      if (initialEventData.owner_name) {
        const split = initialEventData.owner_name.split(' ');
        setValue("Owner.first_name", split.slice(0, -1).join(' '));
        setValue("Owner.last_name", split[split.length - 1]);
      }
    }
  }, [eventData])

  const [updateAnimalProfile, {loading, error}] = useMutation(UPDATE_ANIMAL_PROFILE, {
    onCompleted(data) {
      // console.log('data', data);
    },
    update: editAnimalProfileUpdate
  });

  if (!activeAccountId) {
    return null;
  }

  const onSubmit = async data => {
    const {Owner, ...AnimalProfile} = data;

    const allowedFields = ['name', 'code', 'species', 'breed', 'date_of_birth', 'gender', 'description', 'owner_id', 'neutering', 'breed_id', 'weight'];
    const allowedOwnerFields = ['first_name', 'last_name', 'phone', 'email'];
    const NewAnimalProfile = pick(AnimalProfile, allowedFields);
    const NewOwner = pick(Owner, allowedOwnerFields);
    delete AnimalProfile.Breed;
    delete AnimalProfile.Owner;
    if (Owner?.address) {
      delete Owner.address;
    }

    if (AnimalProfile.date_of_birth) {
      AnimalProfile.date_of_birth = moment(AnimalProfile.date_of_birth, 'DD.MM.YYYY').format()
    }

    try {
      const res = await updateAnimalProfile({
        variables: {
          id: AnimalProfile.id,
          _set: {
            ...NewAnimalProfile,
          },
          owner_id: Owner.id,
          _owner_input: NewOwner,
        }
      });
      const {data: resultData} = res || {};
      message.success(t('Animal profile created!'));
      history.push('/clients')
    } catch (e) {
      message.error(t('Creating animal profile failed'));
      console.log('e', e);
    }

  }

  return (
    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-7xl mx-auto space-y-6 px-2 sm:px-4 lg:px-8">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Animal')}</h3>
              <p className="mt-1 text-sm text-gray-500">
                Information about the animal
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <TextInput
                      label={t('Name')}
                      inputProps={{
                        id: "animal-name",
                        ...register("name", {required: true})
                      }}
                      errors={errors?.name}
                    />
                  </div>
                </div>

                <div>
                  <TextInput
                    label={t('Code')}
                    inputProps={{
                      id: "code",
                      ...register("code")
                    }}
                    errors={errors?.code}
                  />
                  <p className="mt-2 text-sm text-gray-500">{t('Scan or enter the chip code')}</p>
                </div>

                <div>
                  <TextInput
                    label={t('Description')}
                    multi
                    inputProps={{
                      id: "description",
                      rows: 3,
                      ...register(
                        "description"
                      )
                    }}
                    errors={errors?.description}
                  />
                  <p className="mt-2 text-sm text-gray-500">{t('Brief description and info about the animal')}</p>
                </div>

                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <BirthdateInput
                      label={t('Date of birth')}
                      inputProps={{
                        id: "date-of-birth",
                        ...register(
                          "date_of_birth",
                          {
                            required: true,
                            validate: (value) => {
                              const date = DateTime.fromFormat(value, 'dd.MM.yyyy');
                              console.log('date', date);
                              return date.invalid ? t('Please insert a valid birthdate') : true;
                            }
                          },
                        )
                      }}
                      errors={errors?.date_of_birth}
                    />
                    <p className="mt-2 text-sm text-gray-500">{t('DD.MM.YYYY')}</p>
                  </div>
                  <div className="col-span-4 sm:col-span-2">
                    <TextInput
                      label={t('Weight (kg)')}
                      inputProps={{
                        id: "animal-weight",
                        ...register("weight", {required: false})
                      }}
                      errors={errors?.weight}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                      {t('Gender')}
                    </label>
                    <div className="mt-1">
                      <GenderSelect
                        id="gender"
                        {...register("gender", {required: true})}
                      />
                    </div>
                  </div>
                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="species" className="block text-sm font-medium text-gray-700">
                      {t('Species')}
                    </label>
                    <div className="mt-1">
                      <SpeciesSelect
                        id="species"
                        {...register("species", {required: true})}
                      />
                    </div>
                  </div>
                  <div className="col-span-4 sm:col-span-2">
                    <Controller
                      name="breed_id"
                      control={control}
                      rules={{required: false}}
                      id="breed"
                      render={SearchableBreedSelect}
                      errors={errors?.breed_id}
                    />
                  </div>
                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="species" className="block text-sm font-medium text-gray-700">
                      {t('Neutered')}
                    </label>
                    <div className="mt-1">
                      <NeuteringSelect
                        id="neutering"
                        {...register("neutering", {required: true})}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Owner')}</h3>
              <p className="mt-1 text-sm text-gray-500">Make sure that e-mail and phone number are correct and can be
                reached.</p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      label={t('First name')}
                      inputProps={{
                        id: "first-name",
                        autoComplete: 'given-name',
                        ...register("Owner.first_name", {required: true})
                      }}
                      errors={errors?.Owner?.first_name}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      label={t('Last name')}
                      inputProps={{
                        id: "last-name",
                        autoComplete: 'given-name',
                        ...register("Owner.last_name", {required: true})
                      }}
                      errors={errors?.Owner?.last_name}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <TextInput
                      label={t('Email address')}
                      inputProps={{
                        id: "email-address",
                        autoComplete: 'email',
                        ...register(
                          "Owner.email",
                          {
                            validate: (value) => {
                              return (!value && !phone.current) ? t('Either e-mail or phone number must be filled') : true;
                            },
                            pattern: {
                              value: /^\S+@\S+\.\S+$/,
                              message: t('Please insert a valid e-mail')
                            }
                          }
                        )
                      }}
                      errors={errors?.Owner?.email}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <TextInput
                      label={t('Phone number')}
                      inputProps={{
                        id: "phone-number",
                        autoComplete: 'phone',
                        ...register("Owner.phone", {
                          validate: (value) => {
                            return (!value && !email.current) ? t('Either e-mail or phone number must be filled') : true;
                          },
                        })
                      }}
                      errors={errors?.Owner?.phone}
                    />
                  </div>

                  {/*<div className="col-span-6">*/}
                  {/*  <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">*/}
                  {/*    { t('Street address') }*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    id="street-address"*/}
                  {/*    autoComplete="street-address"*/}
                  {/*    {...register("Owner.address.address", { required: false })}*/}
                  {/*    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div className="col-span-6 sm:col-span-6 lg:col-span-2">*/}
                  {/*  <label htmlFor="city" className="block text-sm font-medium text-gray-700">*/}
                  {/*    { t('City') }*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    id="city"*/}
                  {/*    {...register("Owner.address.city", { required: false })}*/}
                  {/*    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div className="col-span-6 sm:col-span-3 lg:col-span-2">*/}
                  {/*  <label htmlFor="state" className="block text-sm font-medium text-gray-700">*/}
                  {/*    { t('State / Province') }*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    id="state"*/}
                  {/*    {...register("Owner.address.state", { required: false })}*/}
                  {/*    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div className="col-span-6 sm:col-span-3 lg:col-span-2">*/}
                  {/*  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">*/}
                  {/*    { t('Postal Code') }*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    id="postal-code"*/}
                  {/*    autoComplete="postal-code"*/}
                  {/*    {...register("Owner.address.postal_code", { required: false })}*/}
                  {/*    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"*/}
                  {/*  />*/}
                  {/*</div>*/}

                  {/*<div className="col-span-6 sm:col-span-3">*/}
                  {/*  <label htmlFor="country" className="block text-sm font-medium text-gray-700">*/}
                  {/*    { t('Country / Region') }*/}
                  {/*  </label>*/}
                  {/*  <select*/}
                  {/*    id="country"*/}
                  {/*    autoComplete="country"*/}
                  {/*    {...register("Owner.address.country", { required: false })}*/}
                  {/*    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
                  {/*  >*/}
                  {/*    <option value="et">{ t('Estonia') }</option>*/}
                  {/*  </select>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Link
            to="/clients"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('Cancel')}
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </form>
  )
}


export default EditAnimal
