import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'

import {GET_ACCOUNT_ROLES} from "../../queries/accountRoles";
import {InlineSpinner} from "../../components/spinner";
import {useAccount} from "../../hooks/use-account";
import Avatar from "react-avatar";
import {Link} from "react-router-dom";


export default function People(props) {
  const { path } = props;
  const { t } = useTranslation();
  const { activeAccountId } = useAccount();
  const { data, loading } = useQuery(GET_ACCOUNT_ROLES, {variables: {accountId: activeAccountId}});

  if(loading) {
    return <InlineSpinner />
  }
  const { AccountRolesWithUsers=[] } = data || {};

  return (
    <section className="mt-5" aria-labelledby="schedule-heading">
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {AccountRolesWithUsers.map((person) => (
            <li key={person.id}>
              <Link to={`${path}/${person.id}`} className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <Avatar size={40} round name={person.displayName || person.email} email={person.email}/>
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p className="text-sm font-medium text-indigo-600 truncate">{person.full_name || person.displayName}</p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span className="truncate">{person.email}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div className="text-sm text-gray-900">{person.phone}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
