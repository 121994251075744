import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {PermissionDialog} from "../../components/dialogs";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_NOTIFICATION, GET_NOTIFICATIONS} from "../../queries/notifications";
import {useToast} from "../../hooks/use-toast";
import moment from 'moment'
import 'moment/locale/et';
import {GET_ANIMAL_PROFILE} from "../../queries/animalProfiles";
import {APPROVE_VISIT_ACCESS, GET_VISIT_ACCESS_REQUEST, GET_VISIT_ACCESS_REQUESTS} from "../../queries/visits";
import {BanIcon, CheckIcon} from "@heroicons/react/outline";
import {insertNotificationUpdate} from "../../queries/updaters";
import demo from "../../utils/demo";

const defaultAnnouncements = [
  {
    id: 3,
    title: 'Teade',
    href: '#',
    preview:
      '01.10: Kõik roosad kanüülid on otsas, tuleb hommikul vara kohe tellida, et jõuaks veel homse päeva sees kohale. Ilusat tööpäeva! Teine vahetus',
  },
  {
    id: 1,
    title: 'Teine kliinik palus juurdepääsu',
    href: '#',
    metadata: {visit_id: '1'},
    preview:
      '15.01: Teine kliinik palus juurdepääsu Camilla Udras visiidile 15.01.2021',
  },
  {
    id: 2,
    title: 'Kolmas kliinik palus juurdepääsu',
    href: '#',
    metadata: {visit_id: '2'},
    preview:
      '11.01: Kolmas kliinik palus juurdepääsu Juki Udras visiidile 11.01.2021',
  },
]



function Notification(props) {

  const { t } = useTranslation();
  const { notification, removeAnnouncement } = props;
  const [permissionDialogVisible, showPermissionDialog] = useState(false);
  const [approvedPermissionState, setApprovedPermission] = useState(false);
  const formattedDate = notification.created_at ? moment(notification.created_at).format('DD.MM') : ''
  const visitAccessRequestId = notification?.metadata?.visit_access_request_id;
  const { displayName, email, AccountRole } = notification?.metadata || {};

  const { data, loading } = useQuery(GET_VISIT_ACCESS_REQUEST, {variables: {id: visitAccessRequestId}, skip: !visitAccessRequestId})

  const { VisitAccessRequest } = data || {};
  const [approveVisitAccess, { loading:approveLoading, error }] = useMutation(APPROVE_VISIT_ACCESS, {
    onCompleted(data) {
      // console.log('data', data);
    },
  });

  let approvedPermission = approvedPermissionState
  let message = notification.preview || `${ formattedDate }: ${notification.message}`;
  let extra = ''
  if(VisitAccessRequest) {
    // console.log('role', VisitAccessRequest.AccountRole);
    extra = `${VisitAccessRequest.AnimalProfile.name} ${VisitAccessRequest.AnimalProfile.Owner.last_name}`
    const visits = (VisitAccessRequest.visits || []).map((visit) => {
      return moment(visit.visit_time).format('DD.MM.YYYY');
    })

    extra += ` ${visits.join(', ')}`

    if(AccountRole) {
      message = `${displayName} (${AccountRole.Account.name}) ${t('has requested access to visit')}:`
    }

    if(VisitAccessRequest.success) {
      approvedPermission = true;
    }
  }



  // console.log('res', displayName, email, AccountRole);

  const approvePermission = async (request) => {
    try {
      if(request && VisitAccessRequest) {
        const res = await approveVisitAccess({variables: {id: VisitAccessRequest.id}})
        setApprovedPermission(true);
        // console.log('res', res);
      }
    } catch(e) {
      // console.log('error', e)
    }
  }
  return (
    <>
      <li key={notification.id} className="py-5">
        <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
          <h3 className="text-sm font-semibold text-gray-800">
            <a href="#" onClick={(e) => {
              e.preventDefault();
              if(VisitAccessRequest) {
                showPermissionDialog(true);
              }
            }} className="hover:underline focus:outline-none">
              {/* Extend touch target to entire panel */}
              <span className="absolute inset-0" aria-hidden="true" />
              {notification.title || t('Notification')}
            </a>
          </h3>
          <p className="mt-1 text-sm text-gray-600 line-clamp-2">{ message }</p>
          { extra ? <p className="mt-1 inline-flex items-baseline  font-bold text-sm text-gray-600 line-clamp-2">
            { approvedPermission ?
              <span className="h-4 w-4 mr-2 rounded-full bg-green-500 flex-shrink-0 self-center ring-8 ring-white">
                <CheckIcon className="h-3 w-3 ml-0.5 mt-0.5 text-white self-center"/>
              </span>
              : <span className="h-4 w-4 mr-2 rounded-full bg-yellow-500 flex-shrink-0 self-center ring-8 ring-white">
                <BanIcon className="h-3 w-3 ml-0.5 mt-0.5 text-white self-center"/>
              </span>}
            { extra }
          </p> : null}
        </div>
      </li>
      <PermissionDialog
        open={permissionDialogVisible}
        setOpen={showPermissionDialog}
        requestPermission={approvePermission}
        requestedPermission={approvedPermission}
        text={{
          title: t('Other clinic requested access to visit'),
          titleSuccess: t('Access granted'),
          body: t('The other clinic needs access to visit: Camilla Udras - 11.01.2021'),
          bodySuccess: t('Access granted'),
          actionLabel: t('Grant Access'),
        }}
      />
    </>
  )
}

export default function Notifications(props) {
  const { t } = useTranslation();
  const [ announcements, setAnnouncements ] = useState(demo() ? defaultAnnouncements : [])
  const [notificationText, setNotificationText] = useState('')
  const { data, loading } = useQuery(GET_NOTIFICATIONS)
  const { message } = useToast();
  const { Notifications = [] } = data || {};
  const [insertNotification, { loading:submitLoading, error }] = useMutation(CREATE_NOTIFICATION, {
    onCompleted(data) {
      // console.log('data', data);
    },
    update: insertNotificationUpdate,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await insertNotification({variables: {
      title: t('Notification'),
      message: notificationText,
    }});

    // console.log('res', res);
    message.success(t('Notification set!'));
    setNotificationText('');
  }

  return <>
    <div className="bg-white px-4 py-5 shadow sm:rounded-t-lg sm:px-6">
      <section aria-labelledby="announcements-title">
        <h2 className="text-base font-medium text-gray-900" id="announcements-title">
          { t('Notifications') }
        </h2>
        <div className="flow-root mt-6">
          { announcements.length > 0 || Notifications.length > 0 ? <ul className="-my-5 divide-y divide-gray-200">
              {Notifications.map(notification => {
                return (<Notification
                  key={notification.id}
                  notification={notification}
                  removeAnnouncement={() => {}}
                />)
              })}
              {announcements.map((announcement) => (
                <Notification
                  key={announcement.id}
                  notification={announcement}
                  removeAnnouncement={(an) => {
                    setAnnouncements(announcements.filter(ann => {
                      return ann.id !== an.id;
                    }))
                  }}
                />
              ))}
            </ul>
            : <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">{ t("No new notifications") }</h3>
            </div>}
        </div>
        { false ? <div className="mt-6">
          <a
            href="#"
            className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            { t('View all') }
          </a>
        </div> : null }
      </section>
    </div>
    <div className="bg-gray-50 px-4 py-6 sm:px-6 shadow sm:rounded-b-lg">
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
            alt=""
          />
        </div>
        <div className="min-w-0 flex-1">
          <form action="#" onSubmit={handleSubmit}>
            <div>
              <label className="sr-only" htmlFor="comment">
                { t('Notification') }
              </label>
              <textarea
                className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                id="comment"
                name="comment"
                rows={3}
                placeholder={ t("Type notification text") }
                value={notificationText}
                onChange={e => {
                  setNotificationText(e.currentTarget.value)
                }}
              />
            </div>
            <div className="mt-3 flex items-center justify-between">
              <a
                className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                href="#"
              >
                {/* Heroicon name: question-mark-circle*/}
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <button
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                type="submit"
              >
                { t('Add notification') }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
}
