import React from 'react'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import {useTranslation} from "react-i18next";
import People from "./People";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Person from "./Person";

const shifts = [
  { name: 'Esimene vahetus', initials: '1.', href: '#', members: 16, time: <>
      <div>08:00-12:00</div>
      <div>13:00-16:00</div>
    </>, bgColor: 'bg-pink-600' },
  { name: 'Teine vahetus', initials: '2.', href: '#', members: 12, time: <>
      <div>11:00-14:00</div>
      <div>15:00-19:00</div>
    </>, bgColor: 'bg-purple-600' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Schedule(props) {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();

  console.log('path', path);
  return <div className="mb-5">
    <div>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">{ t('Shifts') }</h2>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-2">
        {shifts.map((project) => (
          <li key={project.name} className="col-span-1 flex shadow-sm rounded-md">
            <div
              className={classNames(
                project.bgColor,
                'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              )}
            >
              {project.initials}
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a href={project.href} className="text-gray-900 font-medium hover:text-gray-600">
                  {project.name}
                </a>
                <div>
                  {project.time}
                </div>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">Open options</span>
                  <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    <Switch>
      <Route exact path={path}>
        <div className="mt-5">
          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">{ t('Schedule') }</h2>
          <People path={path}/>
        </div>
      </Route>
      <Route path={`${path}/:roleId`}>
        <Person />
      </Route>
    </Switch>
  </div>
}
