import {gql} from "@apollo/client";

export const INSERT_VISIT = gql`
    mutation insertVisit($object: Visit_insert_input!) {
        insertVisit(object: $object) {
            account_role_id
            animal_profile_id
            created_at
            diagnosis_text
            id
            instructions
            notes
            send_instructions
            updated_at
            visit_time
            visit_id
        }
    }

`;


export const GET_PUBLIC_VISITS = gql`
    query getPublicVisits($where: PublicVisit_bool_exp!) {
        PublicVisits(where: $where, order_by: {visit_time: desc}) {
            __typename
            Account {
                __typename
                name
                id
            }
            VisitAccesses {
                __typename
                id
                account_id
                created_at
                updated_at
            }
            id
            visit_time
            visit_id
            animal_profile_id
            account_id
        }
    }
`;

export const GET_VISIT = gql`
    query getVisit($id: uuid!) {
        Visit(id: $id) {
            __typename
            Diagnosis {
                __typename
                code
                created_at
                id
                name
                updated_at
            }
            VisitAttachments {
                __typename
                id
                Attachment {
                    __typename
                    id
                    file
                    original_name
                    display_name
                    name
                    created_at
                    updated_at
                }
            }
            account_role_id
            animal_profile_id
            created_at
            diagnosis_id
            diagnosis_text
            id
            instructions
            notes
            send_instructions
            updated_at
            visit_time
            visit_id
        }
    }
`;


export const REQUEST_VISIT_ACCESS = gql`
    mutation requestVisitAccess($object: VisitAccessRequest_insert_input = {}) {
        insertVisitAccessRequest(object: $object) {
            __typename
            account_role_id
            animal_profile_id
            created_at
            created_visit_access_id
            from_account_id
            id
            metadata
            success
            to_account_id
            updated_at
        }
    }
`;

export const GET_VISIT_ACCESS_REQUESTS = gql`
    query getVisitAccessRequest($animal_profile_id: uuid = "") {
        VisitAccessRequests(where: {animal_profile_id: {_eq: $animal_profile_id}}) {
            account_role_id
            animal_profile_id
            created_at
            from_account_id
            created_visit_access_id
            id
            metadata
            success
            to_account_id
            updated_at
        }
    }
`

export const GET_VISIT_ACCESS_REQUEST = gql`
    query getVisitAccessRequest($id: uuid = "") {
        VisitAccessRequest(id: $id) {
            account_role_id
            animal_profile_id
            created_at
            created_visit_access_id
            from_account_id
            id
            metadata
            success
            to_account_id
            updated_at
            AnimalProfile {
                updated_at
                species
                owner_id
                neutering
                name
                id
                gender
                description
                date_of_birth
                created_by
                created_at
                code
                breed_id
                breed
                Owner {
                    first_name
                    full_name
                    id
                    last_name
                    email
                    created_at
                    phone
                    updated_at
                }
            }
            visits {
                account_role_id
                animal_profile_id
                created_at
                diagnosis_id
                id
                instructions
                notes
                send_instructions
                type
                updated_at
                visit_time
                visit_id
            }
        }
    }
`;

export const APPROVE_VISIT_ACCESS = gql`
    mutation approveVisitAccess($id: uuid = "") {
        approveVisitAccess(id: $id) {
            success
        }
    }
`;

export const INSERT_VISIT_ATTACHMENTS = gql`
    mutation insertVisitAttachments($objects: [VisitAttachment_insert_input!] = {}) {
        insertVisitAttachments(objects: $objects) {
            returning {
                __typename
                attachment_id
                created_at
                id
                updated_at
                visit_id
                visit_id
            }
        }
    }
`;
