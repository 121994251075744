import React, { useEffect, useState } from "react";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import Avatar from 'react-avatar';
import { DateTime } from "luxon";

import {GET_ANIMAL_PROFILE} from "../../queries/animalProfiles";
import {InlineSpinner} from "../../components/spinner";
import {useBreadcrumbs} from "../../hooks/use-breadcrumbs";
import {CreateVisit} from "../visit";
import VisitHistory from "./VisitHistory";
import VisitDetail from "../visit/Detail";
import {PencilIcon} from "@heroicons/react/solid";
import DescriptionForm from "./DescriptionForm";
import Attachments from "./Attachments";

function AnimalDetail(props) {
  const { t } = useTranslation();
  let { path, url, params } = useRouteMatch();
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [ descriptionFormVisible, setDescriptionFormVisible ]= useState(false);
  const { animalId } = params || {}
  const { data, loading } = useQuery(GET_ANIMAL_PROFILE, {variables: {id: animalId}})

  const { AnimalProfile={} } = data || {};

  const { Owner={} } = AnimalProfile;
  const animalFullName = `${AnimalProfile.name} ${Owner.last_name || ''}`;

  useEffect(() => {
    let breadCrumbs = [
      {
        label: t('Clients'),
        to: '/clients'
      }
    ]

    if(AnimalProfile?.id) {
      breadCrumbs.push({
        label: animalFullName,
        to: `/clients/${AnimalProfile.id}`
      })
    }
    setBreadcrumbs(breadCrumbs);

    return () => {
      setBreadcrumbs([]);
    }
  }, [AnimalProfile, animalFullName]);

  if(loading) {
    return <InlineSpinner />
  }

  const ageYears = Math.floor(DateTime.fromISO(AnimalProfile.date_of_birth)
    .diffNow('years').years * -1);
  const ageMonths = Math.floor(DateTime.fromISO(AnimalProfile.date_of_birth)
    .diffNow('months').months * -1) % 12 + 1;
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <Avatar size={64} round name={ animalFullName } />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{ animalFullName }</h1>
                <p className="text-sm font-medium text-gray-500">
                  <span className="text-gray-900">{ AnimalProfile.code }</span> { t('added at') } {" "}
                  <time dateTime={ AnimalProfile.created_at }>{ DateTime.fromISO(AnimalProfile.created_at).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' }) }</time>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                type="button"
              >
                { t('Share') }
              </button>
              <Link
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                to={`/clients/${AnimalProfile.id}/edit`}
              >
                { t('Edit') }
              </Link>
              <Link
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                to={`/clients/${AnimalProfile.id}/attachments`}
              >
                { t('Attachments') }
              </Link>
              <Link
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                to={`/calendar/new/${AnimalProfile.id}`}
              >
                { t('New Appointment') }
              </Link>
              <Link
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                to={`/clients/${AnimalProfile.id}/visits/new`}
              >
                { t('Start visit') }
              </Link>
            </div>
          </div>
          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="applicant-information-title"
                    >
                      { t('Information about the animal') }
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      { t('Species and breed') }
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Species') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{ t(AnimalProfile.species) }</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Breed') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{
                          AnimalProfile?.Breed?.name }</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Date of Birth') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          { AnimalProfile.date_of_birth } ({ageYears} { t('years') } { t('and') } {ageMonths} { t('months') } { t('old')})
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Gender') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{ t(AnimalProfile.gender) }</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Neutered') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{ t(AnimalProfile.neutering) }</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Weight') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{ AnimalProfile.weight ? `${AnimalProfile.weight} kg` : '-' }</dd>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">{ t('Info') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {descriptionFormVisible ? <DescriptionForm profile={AnimalProfile} setFormVisible={setDescriptionFormVisible} /> :
                            <div className="mt-2 flex items-center justify-between">
                              <p className="text-sm text-gray-500 italic whitespace-pre-line">
                                {AnimalProfile.description}
                              </p>
                              <button
                                type="button"
                                className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={(e) => {
                                  setDescriptionFormVisible(true);
                                }}
                              >
                                <PencilIcon className="h-5 w-5" aria-hidden="true"/>
                                <span className="sr-only">{t('Add description')}</span>
                              </button>
                            </div>
                          }
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="applicant-information-title"
                    >
                      { t('Client Information') }
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      { t('Contact information of the pet owner') }
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Owner') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{ Owner.full_name }</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('E-mail') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          { Owner.email }
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">{ t('Phone') }</dt>
                        <dd className="mt-1 text-sm text-gray-900">{ Owner.phone }</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <a
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                      href="#"
                    >
                      { t('View more') }
                    </a>
                  </div>
                </div>
              </section>
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="applicant-information-title"
                    >
                      Kenneliliidu andmed
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Päritud kenneliliidust
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Kasvataja</dt>
                        <dd className="mt-1 text-sm text-gray-900">Tõnu Treener</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Registrikood
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">1234567</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Kenneli nimi
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">Kobe Kennel</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Värv</dt>
                        <dd className="mt-1 text-sm text-gray-900">Pruun</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Saba</dt>
                        <dd className="mt-1 text-sm text-gray-900">Pikk</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <a
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                      href="#"
                    >
                      Vaata kogu infot
                    </a>
                  </div>
                </div>
              </section>
              {/* Comments*/}
              <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        className="text-lg font-medium text-gray-900"
                        id="notes-title"
                      >
                        Märkmed
                      </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6">
                      <ul className="space-y-8">
                        <li>
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="text-sm">
                                <a className="font-medium text-gray-900" href="#">
                                  Viktoria Veterinaar
                                </a>
                              </div>
                              <div className="mt-1 text-sm text-gray-700">
                                <p>
                                  Ducimus quas delectus ad maxime totam doloribus
                                  reiciendis ex. Tempore dolorem maiores. Similique
                                  voluptatibus tempore non ut.
                                </p>
                              </div>
                              <div className="mt-2 text-sm space-x-2">
                            <span className="text-gray-500 font-medium">
                              4d ago
                            </span>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="text-sm">
                                <a className="font-medium text-gray-900" href="#">
                                  Viktoria Veterinaar
                                </a>
                              </div>
                              <div className="mt-1 text-sm text-gray-700">
                                <p>
                                  Et ut autem. Voluptatem eum dolores sint
                                  necessitatibus quos. Quis eum qui dolorem accusantium
                                  voluptas voluptatem ipsum. Quo facere iusto quia
                                  accusamus veniam id explicabo et aut.
                                </p>
                              </div>
                              <div className="mt-2 text-sm space-x-2">
                            <span className="text-gray-500 font-medium">
                              4d ago
                            </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:px-6">
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <form action="#">
                          <div>
                            <label className="sr-only" htmlFor="comment">
                              Kommentaar
                            </label>
                            <textarea
                              className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                              id="comment"
                              name="comment"
                              rows={3}
                              placeholder="Lisa kommentaar"
                              defaultValue={""}
                            />
                          </div>
                          <div className="mt-3 flex items-center justify-between">
                            <a
                              className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                              href="#"
                            >
                              {/* Heroicon name: question-mark-circle*/}
                              <svg
                                className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                            <button
                              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              type="submit"
                            >
                              Kommenteeri
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section
              className="lg:col-start-3 lg:col-span-1"
              aria-labelledby="timeline-title"
            >
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 className="text-lg font-medium text-gray-900" id="timeline-title">
                  { t('History') }
                </h2>
                {/* Activity Feed*/}
                <VisitHistory
                  profile={AnimalProfile}
                />
                <div className="mt-6 flex flex-col justify-stretch">
                  <Link
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    to={`/clients/${AnimalProfile.id}/visits/new`}
                  >
                    { t('Add Visit') }
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </Route>
        <Route path={`${path}/visits/new`} exact>
          <CreateVisit animal={AnimalProfile} />
        </Route>
        <Route path={`${path}/attachments`} exact>
          <Attachments animal={AnimalProfile} />
        </Route>
        <Route path={`${path}/visits/:visitId`}>
          <VisitDetail animalFullName={animalFullName} AnimalProfile={AnimalProfile}/>
        </Route>

      </Switch>
    </>
  )

}

export default AnimalDetail
