import React from "react";
import {useTranslation} from "react-i18next";
import {CogIcon, UserCircleIcon, CalendarIcon} from "@heroicons/react/outline";
import {NavLink, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import AccountRoles from "./AccountRoles";
import {useForm} from "react-hook-form";
import {useToast} from "../../hooks/use-toast";
import {useAccount} from "../../hooks/use-account";
import {useMutation} from "@apollo/client";
import {UPDATE_ACCOUNT_ROLE} from "../../queries/accountRoles";
import TextInput from "../../components/form/text-input";
import {useAuth} from "../../hooks/use-auth";
import {Schedule} from "../schedule";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings(props) {
  const { t } = useTranslation();
  const location = useLocation();
  let { path, url, params } = useRouteMatch();
  const { register, handleSubmit, setError, formState } = useForm();
  const { errors } = formState;
  const { message } = useToast();

  const { activeAccount, activeAccountId, activeRole } = useAccount();


  const subNavigation = [
    { name: t('Profile'), to: '/settings', icon: UserCircleIcon, current: false },
    { name: t('User Roles'), to: '/settings/user-roles', icon: CogIcon, current: false },
    { name: t('Schedule'), to: '/settings/schedule', icon: CalendarIcon, current: false },
  ]

  const [updateAccountRole, { loading, error }] = useMutation(UPDATE_ACCOUNT_ROLE, {
    onCompleted(data) {
      // console.log('data', data);
    },
  });

  if(!activeAccountId || !activeRole) {
    return null;
  }

  const onSubmit = async data => {
    console.log('data', data);
    try {
      const res = await updateAccountRole({variables: {
          id: activeRole.id,
          data: data,
        }});
      const { data:resultData } = res || {};
      message.success(t('User info updated!'));
    } catch (e) {
      message.error(t('Failed to update user info'));
      console.log('e', e);
    }

  }

  console.log('path', url)
  return (
    <div className="max-w-7xl mx-auto space-y-6 px-2 sm:px-4 lg:px-8">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            { t('Account Settings') }
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
        </div>
      </div>
      <div>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-1">
              {subNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.to}
                  className={classNames(
                    item.to === location.pathname
                      ? 'bg-gray-50 text-blue-600 hover:bg-white'
                      : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </NavLink>
              ))}
            </nav>
          </aside>

          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <Switch>
              <Route exact path={path}>
                <section aria-labelledby="user-details-heading">
                  <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h2 id="user-details-heading" className="text-lg leading-6 font-medium text-gray-900">
                            { t('User details') }
                          </h2>
                          <p className="mt-1 text-sm text-gray-500">
                            { t('Update your contact information.') }
                          </p>
                        </div>

                        <div className="mt-6 grid grid-cols-4 gap-6">
                          <div className="col-span-4 sm:col-span-2">
                            <TextInput
                              label={ t('First name') }
                              inputProps={{
                                id: "first-name",
                                defaultValue: activeRole.first_name,
                                ...register("first_name", { required: true })
                              }}
                              errors={errors?.first_name}
                            />
                          </div>

                          <div className="col-span-4 sm:col-span-2">
                            <TextInput
                              label={ t('Last name') }
                              inputProps={{
                                id: "last-name",
                                defaultValue: activeRole.last_name,
                                ...register("last_name", { required: true })
                              }}
                              errors={errors?.last_name}
                            />
                          </div>

                          <div className="col-span-4 sm:col-span-2">
                            <TextInput
                              label={ t('Phone') }
                              inputProps={{
                                id: "phone",
                                defaultValue: activeRole.phone,
                                ...register("phone", { required: true })
                              }}
                              errors={errors?.phone}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          type="submit"
                          className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        >
                          { t('Save') }
                        </button>
                      </div>
                    </div>
                  </form>
                </section>
              </Route>
              <Route exact path={`${path}/user-roles`}>
                <AccountRoles />
              </Route>
              <Route path={`${path}/schedule`}>
                <Schedule />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}
